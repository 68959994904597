import React from 'react'
import svgLogo from '../images/one.svg'
import styled from 'styled-components'

export default () => {
  return (
    <Logo>
      <img src={svgLogo} alt="One" />
    </Logo>
  )
}

const Logo = styled.div`
  max-width: 50px;
  width: 50px;
  height: 50px;
  display: inline-block;
  margin-right: 30px;
  border: 1px solid #fff;
  border-radius: 50%;
`
