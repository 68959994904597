import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import React from 'react'
import Logo from '../components/Logo'
import SettingsIcon from '@material-ui/icons/Settings'

const Header = ({ siteTitle }) => (
  <Head>
    <div className="title">
      <h1>
        <Link to="/">
          <Logo />
          {siteTitle}
        </Link>
      </h1>
      <div className="config">
        <Link to="/config/">
          <SettingsIcon />
        </Link>
      </div>
    </div>
  </Head>
)

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

const Head = styled.header`
  background-color: #5b3c88;
  margin-bottom: 1.45rem;

  > div.title {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 960px;
    min-height: 4.5rem;
    padding: 0 1.0875rem;

    > h1 {
      flex-grow: 4;
      margin: 0;

      a {
        display: flex;
        align-items: center;
        color: white;
        text-decoration: none;
      }
    }

    > div.config {
      a {
        color: white;
        display: flex;
        text-decoration: none;

        > svg {
          font-size: 2.5em;
        }
      }
    }
  }
`

export default Header
